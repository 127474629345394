@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
:root {
    --headingcolor: #000000;
    --blackcolor: #ffffff;
    --bluecolor: #483d73;
    --redcolor: #e11e25;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: "Open Sans", sans-serif;
    

}
button, p{
    font-family:"Inter",sans-serif;
}
button:hover{
    cursor: pointer;
}
html {
    scroll-behavior: smooth;
    font-size: 11px;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

li {
    list-style-type: none;
}
input:focus{
    border-color: var(--bluecolor);
}
.vediocontent{
    width: 30%;
}
@media screen and (max-width: 600px) {
    html{
        font-size: 10px;
    }
    *{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    }
}
p{
    font-size: 1.4rem !important;
}
