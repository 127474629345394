.aboutexpertspic{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.galleryheading{
    font-size: 4rem;
    color: var(--bluecolor);
    text-align: center;
    margin-top: 3rem;

}
.galleryheading>span{
    color: var(--redcolor);
}
.imgcontainer{
    width: 30%;
    height: 290px;
    margin: 1.5rem 1.5rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all 1s ease;
}
.imgcontainer>img{
    width: 100%;height: 100%;
    transition: all 1s ease;
}
.imgcontainer::after{
    position: absolute;
    /* background-color: black; */
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 1s ease;
}
.imgcontainer:hover >img{
    transform: scale(1.1);
}
.imgcontainer>img:hover.imgcontainer::after{
    background-color: black;
    opacity: 0.5;
    /* z-index: 2; */
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .imgcontainer{
        width: 45%;
    }
}
@media screen and (max-width: 600px) {
    .imgcontainer{
        width: 100%;
        height: 250px;
    }
}