.aboutcardscontainer{
    width: 100%;
    min-height:400px
}
.aboutcardscontainer{
    width: 90%;
    margin: auto;
    display: flex;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
}
.aboutcard{
    /* border: 1px solid black; */
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 3rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.10);
    border-radius: 5px;
}
.aboutcard > img{
    width: 80px;
}
.aboutcardheading{
    font-size: 2rem;
    width: 50%;
    text-align: center;
    font-weight: bold;
    color: var(--bluecolor);
    margin: 1rem 0;
}
.aboutcardpara{
    font-size: 1.4rem;
    text-align: center;
    margin-top: 1rem;
    color: black;
}
.aboutnessco{
    width: 100%;
    min-height: 300px;
    /* border: 1px solid black; */
}
.aboutnesscocontainer{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}
.aboutnesscoleft,.aboutnesscoright{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 4rem;
}
.aboutusheading{
    color: var(--redcolor);
    font-size: 3.8rem;
}
.aboutussubheading{
    margin-top: 2rem;
    color: var(--bluecolor);
    font-size: 2.3rem;
}
.aboutusdescription{
    line-height: 2.2rem;
    font-size: 1.5rem;
    color: black;
    font-weight: 400;
    /* text-align: center/; */
}
.aboutdescription{
    width: 100%;
    min-height: 350px;
}
.aboutdescriptioncontainer{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
}
.aboutdescheading{
    color: var(--bluecolor);
    font-size: 3rem;
    text-align: center;
}
.aboutdescheading>span{
    color: var(--redcolor);
}
.aboutdescpara{
    color: black;
    margin-top: 3rem;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: justify;
}
.aboutvision{
    width: 100%;
    min-height: 100vh;
}
.aboutvisioncontainer{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.aboutvisionleft{
    width: 45%;
    margin: 2rem 0;
}
.aboutvisionright{
    width: 45%;
    margin: 2rem 0;
}
.aboutvisionimg{
    width: 100%;
    border-radius: 5px;
}
.aboutvisionheading{
    font-size: 2.8rem;
    color: var(--bluecolor);
    margin-bottom: 4rem;
}
.aboutvisionheading>span{
    color: var(--redcolor);
}
.aboutvisiondesc{
    font-size: 1.4rem;
    color: black;
    margin: 4rem 0;
    line-height: 2.1rem;
}
.aboutskills{
    width: 100%;
    min-height: 500px;
    background-color: #f7f7f7;
}
.aboutskillscontainer{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.aboutskillsleft{
    width: 50%;
    margin: 10rem 0;
}
.aboutskillsheading{
    color: var(--bluecolor);
    font-size: 3.5rem;
}
.aboutskillsheading>span{
    color: var(--redcolor);
}
.aboutskillspara{
    color: black;
    font-size: 1.5rem;
    line-height: 2.3rem;
    margin-top: 2rem;
}
.aboutskillright{
    /* width: 100%; */
    width: 40%;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    /* height: 100%; */
}
.aboutskillcontentbox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
}
.aboutskillcontentleft{
    width: 16%;
}
.aboutskillcontentleft>img{
    width: 80%;
}
.aboutskillcontentright{
    width: 70%;
    margin-left: 1rem;
}
.aboutskillcontentheading{
    font-size: 1.9rem;
    color: var(--bluecolor);
}
.aboutskillcontentdesc{
    font-size: 1.4rem;
    color: black;
}
.aboutexperts{
    width: 100%;
    min-height: 100vh;
}
.aboutexpertcontainer{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.aboutexpertsheading{
    font-size: 3.4rem ;
    color: var(--bluecolor);
    text-align: center;
}
.aboutexpertsheading>span{
    color: var(--redcolor);
}
.aboutexpertspics{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.aboutexpertcontent{
    width: 22%;
}
.aboutexpertspic{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    transition: all .5s ease-in-out;
}
.aboutexpertspic::after{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: rgb(0, 0, 0); */
    content: '';
    transition: all .5s ease-in-out;
}
.aboutexpertcontent:hover .aboutexpertspic::after{
    background-color: black;
    opacity: .6;
}
.aboutexpertspic>img{
    width: 100%;
    height: 100%;
    transition: all .5s ease-in-out;
}
.aboutexpertcontent:hover img{
    transform: scale(1.1);
}
.aboutexpertsname{
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    color: var(--bluecolor);
    margin-top: 2rem;
}
.aboutexpertspost{
    text-align: center;
    font-size: 1.4rem;
    margin-top: .5rem;
    color: black;
}
@media screen and (min-width: 601px) and (max-width: 1024px){
    .aboutexperts{
        min-height: 50vh;
    }
    .mm{
        margin-top: 2rem;
    }
    .aboutcardscontainer{
        flex-wrap: wrap;
        /* border: 1px solid black; */
        justify-content: flex-start;
    }
    .aboutcard{
        width: 45%;
        margin: 1rem 1rem;
        align-items: flex-start;
    }
    .aboutcardheading{
        text-align: start;
    }
    .aboutcardpara{
        text-align: start;
    }
    .aboutnesscocontainer{
        flex-direction: column;
    }
    .aboutnesscoleft,.aboutnesscoright{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 4rem;
    }
    .aboutvisioncontainer{
        flex-direction: column;
    }
    .aboutvisionleft{
        width: 100%;
    }
    .aboutvisionright{
        width: 100%;
    }
    .aboutskillscontainer{
        flex-direction: column;
    }
    .aboutskillsleft{
        width: 100%;
    }
    .aboutskillright{
        width: 100%;
    }
    .aboutexpertcontent{
        width: 30%;
    }
}
@media (max-width:600px){
    .aboutnesscocontainer{
        width: 90%;
    }
    .aboutcardscontainer{
        width: 90%;

    }
    .aboutdescheading{
        text-align: start;
        font-size: 2.8rem;
        width: 100%;
        margin-top: 7rem;
    }
    .aboutcardscontainer{
        flex-wrap: wrap;
        /* border: 1px solid black; */
        justify-content: flex-start;
    }
    .aboutvisionimg{
        /* margin-top: 4rem; */
    }
    .aboutcard{
        width: 100%;
        margin: 1rem 1rem;
        align-items: center;
    }
    .aboutvisionleft{
        margin: 0;
    }
    .aboutvisionright{
        margin: 0;
    }
    .aboutcardheading{
        text-align: center;
    }
    .aboutcardpara{
        text-align: center;
        font-size: 1.4rem;
    }
    .aboutnesscocontainer{
        flex-direction: column;
    }
    .aboutnesscoleft,.aboutnesscoright{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 4rem;
    }
    .aboutvisioncontainer{
        flex-direction: column;
    }
    .aboutvisionleft{
        width: 100%;
    }
    .aboutvisionright{
        width: 100%;
    }
    .aboutskillscontainer{
        flex-direction: column;
    }
    .aboutskillsleft{
        width: 100%;
    }
    .aboutskillright{
        width: 100%;
    }
    .aboutexpertspics{
        flex-direction: column;
    }
    .aboutexpertcontent{
        width: 100%;
        margin: 2rem 0;
    }
}