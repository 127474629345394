
.blogs{
    width: 100%;
    min-height: 100vh;
}
.blogcontainer{
    width: 85%;
    height: 100%;
    margin: 4rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}
.singleblogcard{
    width: 30%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.10);
    margin: 2rem auto;
    transition: all 1s ease;
    border-radius: 10px;
    /* border: 1px solid black; */
    /* min-height: 100vh; */
}
.singleblogcardupper{
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: -1;
    transition: all 1s ease;
    display: flex;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.singleblogcardupper::before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .6;
    z-index: 1;
    transition: all 1s ease;
}
.singleblogcard:hover .singleblogcardupper::before{
    background: var(--redcolor);
    
}
.singleblogcardupper>img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: all 1s ease;
}
.singleblogcardlower{
    width: 100%;
    height: 200px;
    /* border: 1px solid black; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.singleblogcardtitle{
    font-size: 1.7rem;
    /* color: var(--bluecolor); */
    color: black;
}
.singleblogcarddesc{
    font-size: 1.4rem;
    margin-top: 1rem;
    color: black;
    margin-bottom: 2rem;
}
.singleblogbtn{
    padding: 1rem 2rem;
    background-color: transparent;
    color: var(--bluecolor);
    border-radius: 5px;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.blogbtn{
    padding: 2rem;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    /* background-color: var(--bluecolor); */
    border: none;
    color: transparent;
    font-weight: 600;
    font-size: 1.3rem;
    text-shadow: -0px 0px 0 var(--bluecolor); /* Horizontal offset, vertical offset, blur radius, color */
    transition: all 0.5s ease; /* Optional: Add transition effect */
    overflow: hidden;
    /* opacity: 0; */
    z-index: 199;
    border: 1px solid rgba(128, 128, 128, 0.397);
}
  .blogbtnarrow{
    color: var(--bluecolor);
  }
  .blogbtn:hover .blogbtncon{
    /* opacity: 0; */
    transform: translateX(-5px);
    /* filter: blur(1px); */
  }
  .blogbtn:hover {
    color: var(--redcolor);
    text-shadow: -300px 0px 0 rgba(255, 255, 255, 0.001); /* Move shadow to the left on hover */
    /* background-color: var(--redcolor); */
    transform: scale(1.05);
    border: 2px solid var(--redcolor);
  }
  .blogbtn:hover .blogbtnarrow {
    color: var(--redcolor);
  }
  .blogbtnarrow{
    transition: all 0.5s ease; /* Optional: Add transition effect */
  }
  .blogbtnarrow {
    transform: translateX(5px);
  }
.singleblogcard:hover .singleblogcardupper>img{
    transform: scale(1.1);
}
.blogdescsection{
    width: 100%;
    min-height:100vh;
}
.blogdescsectioncontainer{
    width: 85%;
    margin: auto;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
}
.blogmainheading{
    font-size: 4rem;
    color: var(--bluecolor);
    margin-top: 7rem ;
}
.blogdesc{
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: black;
    font-family: 'Poppins', sans-serif;
    margin: 2rem 0;
}
.blogheading{
    font-size: 2.5rem;
    color: var(--bluecolor);
    margin-top: 5rem;
}
.blognavigationbtns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.marr{
    margin-top: 7rem;
}
.marrr{
    margin-bottom: 10rem;
}
.blognavbtn{
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
    color: var(--bluecolor);
}
.blognavbtn:hover{
    color: var(--redcolor);
}
.blogimg{
    margin: 3rem 0;width: 40%;
}
.blognavigationdesc{
    font-size: 1.7rem;
}
.bloglinks{
    color: var(--bluecolor);
}
.bloglinks:hover{
    color: var(--redcolor);
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
    .singleblogcard{
        width: 45%;
    }
    .singleblogcardupper{
        height: 200px;
    }
}
@media  (max-width: 600px) {
    .singleblogcard{
        width: 100%;
    }
    .blogmainheading{
        font-size: 3rem;
    }
    .singleblogcardupper{
        height: auto;
    }
    .blognavigationdesc{
        font-size: 1.1rem;
    }
    .blognavigationdesc:nth-child(1){
        text-align: left;
    }
    .blognavigationdesc:nth-child(2){
        text-align: right;
    }
    .blogimg{
        width: 100%;
    }
}
