.productpage{
    width: 100%;
    min-height: 100vh;
}
.productpagecontainer{
    width: 90%;
    margin: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productpagebtncontainer{
    margin-top: 5rem;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.productfilterbtn{
    /* background-color: blue; */
    background-color: white;
    border: 2px solid grey;
    color: var(--bluecolor);
    margin: .5rem;
    padding: 1rem 1rem;
    font-size: 1.5rem;
    font-size: 600;
}
.filter-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}
  .productpagelist{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }
  .productpagecard{
    width: 21%;
    height: auto;
    /* border: 1px solid black; */
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.096);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin: 1rem .5rem;
    text-align: center;
  }
  .productpageupper{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productpagelower{
    width: 100%;
    height: 180px;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .productpageupper>img{
      width: 100%;
      height: 100%;
  }
  .productpageheading{
    font-size: 1.9rem;width: 100%;
    text-align: left;
    color: var(--bluecolor);
    transition: all .4s ease-in-out;
    margin: 2rem 0;
  }
  .productpageheading:hover{
    color: var(--redcolor);
  }
  .productpagelink{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5rem;width: 100%;
    text-align: left;
    /* margin-top: 2rem; */
    color: var(--bluecolor);
    transition: all 0.2s ease;font-weight: 700;
  }
  .productpagelink:hover{
    color: var(--redcolor);
    letter-spacing: 0.5px;
  }

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .productpagebtncontainer{
    width: 100%;
    justify-content: flex-start;
  }
  .productpagecard{
    width: 40%;
    text-align: start;
    margin: 1.5rem 1.5rem;
  }
}
@media (max-width:600px) {
  .productpagebtncontainer{
    width: 100%;
    justify-content: flex-start;
  }
  .productpagecard{
    width: 100%;
    text-align: start;
    margin: 1.5rem auto;
  }
}