/* ABOUT US  */
.homeaboutus,
.iotsection,
.article {
  width: 100%;
  min-height: 100vh;
}

.sectionContainer,
.iotcontainer,
.articlecontainer {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.sectionContainer {
  justify-content: space-between;
  align-items: center;
}
.leftabout {
  position: relative;
  width: 45%;
  /* margin: auto; */
}

.rightabout {
  width: 45%;
  /* margin: auto; */
}

.leftaboutpic {
  width: 83%;
  border-radius: 15px;
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: auto;
  overflow: hidden;
}
.leftaboutpic::before {
  position: absolute;
  top: 0;
  left: -75%;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.leftaboutpic2 {
  background: url("https://www.nesscoindia.com/Assets/images/resource/nessco-team-pic1.webp")
    top center/contain;
  /* border: 1px solid black; */
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: -90px;
  right: 0px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  /* zoom: 115%; */
  /* transform: scale(0.8); */
  /* transform:scale3d(); */
}

.playerbtn {
  color: var(--redcolor);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  font-size: 2rem;
  border-radius: 1000%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  border: none;
  /* z-index: -1; */
}

/* .youtubebtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  font-size: 7rem;
  padding: 1rem;
  margin-right: 2rem;
} */
.playerbtn:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid white;
  animation: hamburger_puls 2s ease infinite;
}
.playerbtn:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid white;
  animation: hamburger_puls2 2s 1s ease infinite;
}

.aboutusbtn {
  background-color: red;
  background-image: linear-gradient(45deg, #483d73, #e11e25 100%);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  width: fit-content;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  border-radius: 100px;
  font-weight: 300;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
}

.aboutheading {
  font-size: 2.4rem;
  color: var(--bluecolor);
  margin-bottom: 2rem;
  font-family: "Inter", sans-serif;
}

.small {
  font-size: 1.5rem;
  font-weight: 600;
}

.aboutheading > span {
  color: var(--redcolor);
}

.aboutpara {
  font-weight: 400;
  color: black;

  font-size: 1.4rem;
  margin-bottom: 2rem;
  word-spacing: 4px;
  font-family: "Open Sans", sans-serif;
}

.aboutbtnsection {
  display: flex;
}
.aboutpara > a {
  color: var(--bluecolor);
}
.aboutpara > a:hover {
  color: var(--redcolor);
}
.getaquotebtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 10px;
  background-color: var(--bluecolor);
  color: white;
  border: none;
  transition: all 0.5s ease;
  margin-top: 3rem;
}

.getaquotebtn:hover {
  background-color: var(--redcolor);
}

.signatureimg {
  width: 30%;
  margin-left: 2rem;
}

/* IOT SECTION  */

.leftiot {
  width: 45%;
  margin: auto;
}
.rightiot {
  width: 45%;
  margin: auto;
}
.iotheading {
  color: var(--bluecolor);
  font-size: 3.5rem;
}
.iotheading > span {
  color: var(--redcolor);
}
.iotpara {
  font-size: 1.4rem;
  line-height: 25px;
  color: black;

  word-spacing: 1px;
  margin-top: 1rem;
  text-align: justify;
}
.iotlist {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 2rem 0;
}
.iotlist > li {
  width: 50%;
  margin: 0.8rem 0;
  font-size: 1.4rem;
  position: relative;
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  line-height: 25px;
  flex-wrap: wrap;
  flex-direction: row;
  z-index: -1;
  color: black;
  padding-left: 1.5rem;
}
.iotlist li:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  left: 0px;
  top: 6px;
  border-radius: 50%;
  background: -webkit-linear-gradient(45deg, #483d73, #e11e25 100%);
  z-index: -1;
}
.iotlist li:after {
  position: absolute;
  left: 2px;
  top: 8px;
  content: "";
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.rightiot > img {
  width: 100%;
}
.aboutusbtn {
  font-weight: 500;
  font-size: 1.3rem;
}
/* PRESENCE */

.presenceSection {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
  position: relative;
  /* z-index: -1; */
}
.presenceContainer {
  width: 85%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.staticimage1 {
  position: absolute;
  top: 0;
  left: 100px;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-209.png")
    no-repeat center center/cover;
  width: 100%;
}
.staticimage2 {
  position: absolute;
  background: url(https://www.nesscoindia.com/Assets/images/shape/shape-210.png)
    no-repeat;
  width: 353px;
  height: 303px;
  top: 0;
  left: 0;
  z-index: 1;
}
.leftpresence {
  width: 50%;
  margin-top: 7rem;
}
.rightpresence {
  width: 50%;
  margin-top: 7rem;
}
.presenceheading {
  color: var(--bluecolor);
  font-size: 3.5rem;
}
.presencepara {
  font-size: 1.4rem;
  color:black;
  margin-top: 2rem;
  font-weight: 400;
  line-height: 2.2rem;
}
.mm {
  margin-top: 3rem;
}
.presenceheading > span {
  color: var(--redcolor);
}
.leftpresence > img {
  width: 100%;
  margin-top: 5rem;
  margin-left: 0.25rem;
  margin-bottom: 1rem;
}
.presenceCard {
  background: white;
  display: flex;
  margin-left: 2rem;
  /* height: auto; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: -1;
  /* border: 2px solid black; */
  width: 80%;
  margin-left: auto;
  border-radius: 10px;
  padding: 3rem;
  margin-top: 4rem;
  background-color: white;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
}
.presenceinpfields {
  width: 100%;
  padding: 1.5rem 2rem;
  margin: 1rem auto;
  background-color: #f7f7f7;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
}
.presenceinpfields:focus {
  border-color: var(--bluecolor);
}
.presencecardtitle {
  font-size: 3rem;
  color: var(--bluecolor);
  margin-right: 3rem;
  margin-bottom: 2rem;
}
/* SUBSCRIBE */

.subscribe {
  width: 100%;
  height: 100px;
  background-color: var(--bluecolor);
}
.icon1 {
  width: 50px;
}
.subsribecontainer {
  width: 87%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.leftsubscribe,
.rightsubscribe {
  height: 100%;
  width: 45%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subsribeheading {
  margin-left: 2rem;
  font-size: 1.8rem;
  color: white;
  font-weight: 500;
}
.rightsubscribe > input {
  padding: 2rem;
  font-size: 1.5rem;
  width: 70%;
  height: 55%;
}
.subscribebtn {
  width: 30%;
  height: 55%;
  margin-left: 1.75rem;
  /* padding: 2rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background-color: #001b47;
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  z-index: 0;
}
.subscribebtn:hover {
  /* background-color: white; */
  color: var(--redcolor);
  /* background-color: transparent; */
}
.subscribebtn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: white;
  z-index: -1;
  color: var(--bluecolor);
  transition: 0.8s;
  top: 0;
  border-radius: 0 0 50% 50%;
}
.subscribebtn:hover::before {
  height: 180%;
}

/* ARTICLE  */
.article {
  background: #f0f0f0;
}
.articlecontainer {
  width: 88.5%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.articleheadings {
  font-size: 3rem;
  color: var(--bluecolor);
}
.articleheadings > span {
  color: var(--redcolor);
}
.articlecards {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin: 4rem 0;
}
.articlecard {
  width: 28%;
  /* border: 2px solid black; */
  position: relative;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.uppersection {
  display: flex;
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}
.uppersectionoverlaylayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(0deg, #483d73, #e11e25 100%);

  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}
.uppersection > img {
  width: 100%;
  transition: all 1s ease;
}
.articlecard:hover .uppersection > img {
  position: relative;
  transform: scale(1.1);
  overflow: hidden;
}
.articlecard:hover .uppersection .uppersectionoverlaylayer {
  opacity: 0.7;
}
/* .articlecard:hover .uppersection>img::before{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  opacity: .4;
  background: -webkit-linear-gradient(0deg, #483d73, #e11e25 100%);
  z-index: 1;
} */
.lowersection {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 3rem;
  overflow: hidden;
}
.articleheading {
  font-size: 1.7rem;
  color: black;
  font-weight: 600;
  /* margin-bottom: 1rem; */
  transition: all 0.5s ease;
}
.articleheading:hover {
  color: var(--redcolor);
}
.articledescription {
  font-size: 1.3rem;
  margin: 2rem 0;
  color: black;
}
.articlelink {
  color: var(--bluecolor);
  font-size: 1.4rem;
}
.articlelink > img {
  width: 35%;
}
/* CREATION SECTION  */
.creation {
  width: 100%;
  height: 70vh;
  /* background-color: khaki; */
  background: url("https://www.nesscoindia.com/Assets/images/background/video-bg.webp")
    no-repeat center center/cover;
  position: relative;
  /* z-index: -1; */
  overflow: hidden;
}
.creationpattern1 {
  left: 0;
  bottom: 0;
  position: absolute;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-13.png")
    no-repeat center center/cover;
  z-index: 100;
  width: 397px;
  height: 273px;
  animation: walkingshadow 5s linear infinite;
  z-index: 1;
}
.creationpattern2 {
  width: 262px;
  height: 222px;
  right: -30px;
  top: -15px;
  position: absolute;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-15.png")
    no-repeat center center/cover;
  z-index: 100;
  animation: walkingshadow 5s linear infinite;
  z-index: 1;
}
.creationpattern3 {
  position: absolute;
  left: 0;
  bottom: 0;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-14.png")
    no-repeat center;
  width: 337px;
  height: 291px;
  background-repeat: no-repeat;
  z-index: 1;
}
.creationpattern4 {
  position: absolute;
  right: 0;
  top: 0;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-16.png")
    no-repeat center;
  width: 312px;
  height: 301px;
  background-repeat: no-repeat;
  z-index: 1;
}

.creation::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  background: #000000;
  opacity: 0.6;
  z-index: 1;
}
.creationcontainer {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  position: relative;
  z-index: 2;
}
.creationcontent {
  width: 55%;
}
.creationheading {
  color: white;
  font-size: 3.6rem;
  margin-bottom: 1rem;
}
.creationdesc {
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 2rem 0;
}
.creationvideo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  border: none;
  color: var(--bluecolor);
  font-size: 1.3rem;
  font-weight: 800;
  background-color: white;
  border-radius: 5px;
}
.creationbtns {
  padding: 1rem 1.5rem;
  /* height: 50%; */
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* background-color: var(--bluecolor); */
  border: none;
  color: transparent;
  font-weight: 600;
  font-size: 1.3rem;
  text-shadow: -0px 0px 0 var(--bluecolor); /* Horizontal offset, vertical offset, blur radius, color */
  transition: all 0.5s ease; /* Optional: Add transition effect */
  overflow: hidden;
  /* opacity: 0; */
  z-index: 199;
  border: 1px solid rgba(128, 128, 128, 0.397);
}
.creationbtnarrow {
  color: black;
}
.creationbtns:hover .bannerbtncon {
  /* opacity: 0; */
  transform: translateX(-5px);
  /* filter: blur(1px); */
}
.creationbtns:hover {
  color: var(--redcolor);
  text-shadow: -300px 0px 0 rgba(255, 255, 255, 0.001); /* Move shadow to the left on hover */
  /* background-color: var(--redcolor); */
  transform: scale(1.05);
  border: 2px solid var(--redcolor);
}
.creationbtns:hover .creationbtnarrow {
  color: var(--redcolor);
}
.creationbtnarrow {
  transition: all 0.5s ease; /* Optional: Add transition effect */
}
.creationbtnarrow {
  transform: translateX(5px);
}
.youtubebtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  font-size: 7rem;
  padding: 1rem;
  margin-right: 2rem;
  background-color: transparent;
  z-index: 10000;
}
.youtubebtn:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid white;
  animation: hamburger_puls 2s ease infinite;
}
.youtubebtn:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid white;
  animation: hamburger_puls2 2s 1s ease infinite;
}
.youtubebtn:hover {
  background-color: white;
  color: black;
}
/* INFINTE COMPONENT  */

.logos {
  overflow: hidden;
  padding: 30px 0;
  background: white;
  white-space: nowrap;
  position: relative;
  z-index: 0;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 150px;
  height: 100%;
  content: "";
  z-index: 2;
}
.iotsection{
  height: 100vh;
}
.iotcontainer {
  width: 89.5%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 20s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}
/* HOMESTAT  */
.homestats {
  width: 100%;
  height: 30vh;
  background-color: var(--bluecolor);
  position: relative;
  overflow: hidden;
}
.bghomestat1 {
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-21.png")
    no-repeat center center;
  width: 170px;
  height: 170px;
  position: absolute;
  left: 10%;
  top: -25%;
  transform: scale(1.4);
}
.bghomestat2 {
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-22.png")
    no-repeat center center;
  width: 170px;
  height: 170px;
  position: absolute;
  right: 5%;
  bottom: -53%;
  transform: scale(1.4);
}
.homstatscontainer {
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.homestatheading {
  font-size: 5rem;
  color: white;
}
.homestatpara {
  color: white;
  font-size: 1.3rem;
}
.homestatcontentbox {
  min-width: 25%;
}
.homestatcontent {
  /* width:25%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* BANNER  */
.mySwiper {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.bannerslide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.bannerslide::after {
  position: absolute;
  content: "";
  background: url("https://www.nesscoindia.com/Assets/images/banner/banner-image-nessco-2.webp")no-repeat center
    center/cover;;
  /* background: url("../Assets/images/resource/drupabanner.png") no-repeat center center ; */
  background-size:cover;
  animation: anima 5s ease forwards infinite;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: -1;
  /* transform: scale(0.6); */
}

.imglayer2 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-2.png");
  width: 425px;
  height: 576px;
  background-repeat: no-repeat;
}
.imglayer5 {
  position: absolute;
  z-index: 1;
  top: -204px;
  left: -147px;
  background: url(https://www.nesscoindia.com/Assets/images/resource/drupa.webp);
  width: 425px;
  height: 576px;
  background-repeat: no-repeat;
  transform: scale(0.4);
}
/* .imglayer6{
  position: absolute;
  right: 10%;
  top: 10%;
  color: white;
  font-size: 4rem;
}
.imglayer7{
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  font-size: 4rem;
} */
.imglayer1 {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-3.png");
  width: 425px;
  height: 576px;
  background-repeat: no-repeat;
}
.imglayer3 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-4.png");
  width: 442px;
  height: 273px;
  background-repeat: no-repeat;
}
.imglayer4 {
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  background: url("https://www.nesscoindia.com/Assets/images/shape/shape-5.png");
  width: 382px;
  height: 291px;
  background-repeat: no-repeat;
}

.bannerslidecontainer {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bannerbtn {
  padding: 1rem 1.5rem;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* background-color: var(--bluecolor); */
  border: none;
  color: transparent;
  font-weight: 600;
  font-size: 1.3rem;
  text-shadow: -0px 0px 0 var(--bluecolor); /* Horizontal offset, vertical offset, blur radius, color */
  transition: all 0.5s ease; /* Optional: Add transition effect */
  overflow: hidden;
  /* opacity: 0; */
  z-index: 199;
  border: 2px solid rgba(128, 128, 128, 0);
  margin-top: 10rem;
}
.bannerbtnarrow {
  color: black;
}
.bannerbtn:hover .bannerbtncon {
  /* opacity: 0; */
  transform: translateX(-5px);
  /* filter: blur(1px); */
}
.bannerbtn:hover {
  color: var(--redcolor);
  text-shadow: -300px 0px 0 rgba(255, 255, 255, 0.001); /* Move shadow to the left on hover */
  /* background-color: var(--redcolor); */
  transform: scale(1.05);
  /* border: 2px solid var(--redcolor); */
}
.bannerbtn:hover .blogbtnarrow {
  color: var(--redcolor);
}
.bannerbtnarrow {
  transition: all 0.5s ease; /* Optional: Add transition effect */
}
.bannerbtnarrow {
  transform: translateX(5px);
}

.bannerslideheading {
  /* width: 100%; */
  text-align: initial;
  color: white;
  font-size: 6rem;
  font-weight: 600;
  height: fit-content;
  /* margin-bottom: 4rem; */
  font-family: "Open Sans", sans-serif;
}
.bannerslidepara {
  color: white;
  font-size: 2rem;
  margin-top: 4rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
.bannerslideheading {
  display: block;
  width: fit-content;
  font-size: 7rem;
  position: relative;
  font-weight: 700;
  color: transparent;
  animation: text_reveal 2s ease forwards;
  animation-delay: 2s;
}
.bannerslideheading span {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: white;
  animation: text_reveal_box 1.5s ease;
  animation-delay: 1s;
}

.bannerslidepara {
  display: block;
  animation: parashift 10s ease forwards;
}
@keyframes parashift {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  20% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
/* @keyframes text_reveal_box {
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes text_reveal {
  100% {
    color: white;
  }
} */
@keyframes text_reveal_box {
  50% {
    width: 100%;
    left: 0;
    /* transform: scaleX(0); */
  }
  100% {
    width: 0;
    left: 100%;
    /* transform: scaleX(1); */
  }
}

@keyframes text_reveal {
  100% {
    color: white;
  }
}
@keyframes anima {
  0% {
    transform: scale(1);
  }
  99% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.2);
  }
}

.wenessco {
  width: 100%;
  height: 35vh;
  background-color: #f7f7f7;
  overflow: hidden;
}
.wenesscocontainer {
  width: 86.5%;
  height: 100%;
  margin: 1rem auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.wenesscoleft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  margin-left: 1rem;
}
.wenesscoright {
  width: 100%;
}
.wenesscoheading {
  color: var(--bluecolor);
  font-size: 3.3rem;
  font-family: "Arial", sans-serif;
}
.wenesscoheading > span {
  color: var(--redcolor);
}
.wenesscobtn {
  font-size: 2rem;
  margin: 0.5rem 1.5rem;
  transition: all 0.5s ease;
  font-weight: 100;
  /* color: rgba(0, 0, 0, 0.63); */
  color: rgb(37, 35, 35);
}
.wenesscobtn:hover {
  cursor: pointer;
  color: var(--redcolor);
  transform: scale(1.1);
}
.wenesscoright {
  width: 90%;
  height: 80%;
  /* border: 1px solid black; */
}
.wenesscocard {
  /* height: 200px ; */
  /* border: 1px solid black; */
  background: white;
  width: 95%;
  height: 150px;
  padding: 1rem;
  margin: auto;
  display: flex;
}
.wenesscocardleft {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wenesscocardright {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
}
.wenesscocardleft > img {
  width: 100%;
}
.nesscocardheading {
  color: var(--bluecolor);
  font-size: 1.7rem;
  font-family: "Inter", sans-serif;
}
.nesscocardpara {
  font-size: 1.2rem !important;
  color: black;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}
.imglayer1 {
  position: absolute;
}
.wenesscobuttons {
  /* display: none; */
  width: 100%;
  display: flex;
}
/* TESTIMONIAL SECTION  */

.testimonials {
  width: 100%;
  height: auto;
}
.testimonialscontainer {
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: auto;
  margin-top: 3rem;
}
.testimonialsleft {
  width: 24%;
  margin-top: 2rem;
}
.testimonialsright {
  width: 70%;
  margin-top: 2rem;
}
.testimonialsheading {
  font-size: 3.5rem;
  color: var(--redcolor);
  margin-bottom: 2rem;
  margin-top: 3rem;
}
.testimonialsheading > span {
  color: var(--bluecolor);
}
.testimonialowner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-right: 7rem;
  margin-bottom: 5rem;
}
.testimonialsec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: auto 3rem;
  /* position: relative; */
}
.testimonialcard::after {
  position: absolute;
  content: "";
  /* background: #fff; */
  width: 30px;
  height: 30px;
  left: 0px;
  bottom: -28px;
  box-shadow: 0 30px 70px rgba(99, 78, 78, 0.185);
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%, 0% 0%);
  background-color: white;
  z-index: 1;
}
.testimonialowneravatar {
  width: 60px;
  height: 60px;
  background-color: grey;
  border-radius: 100%;
}
.testimonialowneravatar > img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.testimonialownername {
  font-size: 2rem;
  color: var(--bluecolor);
  font-weight: 600;
  margin-left: 2rem;
}
.testimonialscards {
  z-index: 100;
  display: flex;
  overflow-x: visible;
}
.testimonialscards {
  width: 100%;
}
.testimonialcard {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid black; */
  background-color: white;
  padding: 4rem 3rem;
  position: relative;
  box-shadow: 10px 20px 30px 0 rgba(0, 0, 0, 0.15);
}
.testimonialdesc {
  font-size: 1.4rem;
  color: black;

  line-height: 2rem;
  margin-top: 2rem;
}
/* PRODUCTS  */
.product {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}
.productcontainer {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.align {
  align-self: center;
  margin-top: 4rem;
}
.productbox {
  /* padding: 1rem ; */
  /* border: 1px solid black; */
  /* padding: 1rem; */
  margin: 1rem 0;
}
.productssscard {
  /* margin: 2rem; */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  /* box-shadow: 0 0 40px 0 #0000001a; */
  margin: 0 2rem;
  border-radius: 30px;
  font-family: "Inter", sans-serif;
}
.productsssdesc {
  align-self: center;
  margin-top: 2rem;
  text-align: center;
  font-size: 1.5rem;
  color: black;
  width: 60%;
  font-family: "Open Sans", sans-serif;
}
.productsssupper {
  width: 100%;
}
.productsssupper > img {
  width: 100%;
  border-radius: 10px;
}
.productssslower {
  width: 100%;
  /* height: 150px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.productsssheading {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  color: var(--bluecolor);
  margin: 0.5rem 0;
  transition: all 0.5s ease;
}
.productsssheading:hover {
  color: var(--redcolor);
}
.productssslink {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: var(--bluecolor);
  font-weight: 500;
  margin-bottom: 3rem;
  transition: all 0.5s ease;
  margin: 1rem 0;
  padding: 1rem ;
}
.productssslink:hover {
  cursor: pointer;
  color: var(--redcolor);
  transform: scale(1.1);
}
.productssstitle {
  text-align: center;
  font-size: 3rem;
  color: var(--bluecolor);
}
.bannersliderbtn {
  position: absolute;
  z-index: 100;
}
.bannersliderbtn:hover {
  cursor: pointer;
}
.home-page {
  width: 100%;
  /* text-; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 100;
}

.component {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.control-buttons {
  position: absolute;
  top: 50%;
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.button {
  cursor: pointer;
  padding: 10px 20px;
}
.validations {
  width: 100%;
  margin: 0 auto;
}
.validations > input {
  width: 100%;
}

.drupa{
  position: relative;
  width: 100%;
  height: 42vh;
  background:url('https://www.nesscoindia.com/Assets/images/resource/drupabanner1.png') center center ;
  background-size: contain;
  background-repeat: no-repeat;
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(151,41,247,1) 0%, rgba(24,22,39,1) 90% ); */
  /* background: linear-gradient(to left, #e11e25, #483d73); */
}
.drupa2{
  position: relative;
  width: 100%;
  height: 42vh;
  background:url('https://www.nesscoindia.com/Assets/images/resource/drupabanner2.png') center center ;
  background-size:contain;
  background-repeat: no-repeat;
  /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(151,41,247,1) 0%, rgba(24,22,39,1) 90% ); */
  /* background: linear-gradient(to left, #e11e25, #483d73); */
}
.drupalayers{
  position: absolute;
  width: 110px;
  /* box-shadow: 0 0 150px 0 black; */
}
.drupadates,.drupalocation,.drupabooth{
  position: absolute;
  display: flex;
}
.drupadates{
  top: 25%;
  left: 13%;
}
.drupalocation{
  top: 44%;
  left: 13%;
}
.drupabooth{
  top: 62%;
  left: 13%;
}
.drupalayers{
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
}
.drupaupdatesright,.drupalocationright{
  display: flex;
  flex-direction: column;
}
.drupadatesright>p,.drupalocationright >p{
  color: white;
  font-weight: 600;
  font-size: 1.1rem !important;
}
.drupacontainer{
  width: 90%;
  margin: auto;
}
.drupadatesleft,.drupalocationleft{
  display: flex;
  justify-content: center;
  align-items: center;
}
.drupatablecontent{
  display: flex;
  flex-direction: column;
  width: 300px;
  /* border: 1px solid white; */
  border-collapse:separate;
  position: absolute;
  bottom: 0;
  left: 0;
}
.drupatable{
  display: flex;
  width: 100px;
}
.drupacontent{
  width: 50%;
  text-align: center;
  /* border: 2px solid white; */
  color: white;
  font-weight: 600;
  font-size: 1.1rem;
}
.drupacontent:nth-child(1){
  text-align: start;
}
.drupacontent:nth-child(2){
  text-align: start;
}
.drupaleftlogo{
  font-size: 2rem;
  margin-right: 1rem;
  color: white;
}

@keyframes drupa {
  0%{
    left: 0%;
  }
  100%{
    left:100%;
  }
}

/* ////////////////////////////////// */
/* MEDIA QUERY FOR TABLET */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .bannerslide {
    width: 80%;
    margin: auto;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }
  .home-page {
    display: inline;
    align-items: start;
    justify-content: start;
    text-align: start;
  }
  .bannerslideheading {
    font-size: 5rem;
  }
  .bannerslidepara {
    text-align: start;
  }
  .wenesscobuttons {
    display: none;
  }
  .wenesscocontainer {
    overflow-x: scroll;
  }
  .wenesscocontainer {
    overflow: hidden;
    flex-direction: column;
  }
  .wenesscoleft {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .wenesscocard {
    min-width: 50%;
  }

  .wenesscocardleft {
    width: 100%;
  }
  .wenesscocardleft > img {
    width: 70%;
  }
  .wenesscocardright {
    display: none;
  }
  .sectionContainer {
    width: 90%;
    flex-direction: column;
  }
  .leftabout {
    width: 100%;
    margin-top: 4rem;
  }

  .rightabout {
    width: 100%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .leftaboutpic2 {
    width: 30%;
    height: 200px;
  }

  .getaquotebtn {
    height: 5rem;
  }
  .signatureimg {
    width: 15%;
  }
  .creation {
    width: 100%;
    height: 100vh;
    background-color: khaki;
    background: url("https://www.nesscoindia.com/Assets/images/background/video-bg.webp")
      no-repeat bottom center/150%;
  }

  .creation::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    background: #000000;
    opacity: 0.6;
    z-index: 1;
  }
  .creationcontainer {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    position: relative;
  }
  .creationcontent {
    width: 65%;
  }
  .youtubebtn {
    margin-bottom: 4rem;
  }
  .iotcontainer {
    flex-direction: column;
  }
  .leftiot {
    width: 100%;
  }
  .rightiot {
    align-items: flex-start;
    justify-content: flex-start;
    width: 60%;
    margin: 0;
    margin: 4rem 0;
  }
  .presenceContainer {
    width: 90%;
    flex-direction: column;
  }
  .leftpresence {
    width: 100%;
    margin-top: 0;
  }
  .leftpresence > img {
    width: 60%;
  }
  .rightpresence {
    width: 100%;
    margin-top: 0;
  }
  .presenceCard {
    width: 100%;
  }
  .mm {
    margin-top: 10rem;
  }
  .testimonials {
    height: auto;
  }
  .testimonialscontainer {
    min-height: 300px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .testimonialsleft {
    width: 100%;
    margin: 3rem 0;
  }
  .testimonialsright {
    width: 100%;
  }
  .testimonialscards {
    width: 100%;
  }
  .testimonialsec {
    width: 100%;
  }
  .testimonialcard {
    width: 85%;
  }
  .testimonialowner {
    margin-right: 0;
    margin-bottom: 5re;
  }
  .homestats {
    height: 45vh;
  }
  .homestatcontentbox {
    min-width: 45%;
  }
  .articlecards {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .articlecard {
    width: 45%;
    margin: auto;
  }
  .condition2 {
    margin: 0;
    margin-top: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 5rem;
  }
  .subscribe {
    width: 100%;
    height: 130px;
  }
  .subscribebtn {
    height: 65%;
  }
  .subsribecontainer {
    width: 82%;
    margin: auto;
    flex-direction: column;
  }
  .leftsubscribe {
    width: 100%;
  }
  .rightsubscribe {
    width: 90%;
  }
  /* .productbox {
    overflow-x: hidden;
    margin: 1rem auto;
  }
  .product {
    min-width: 28%;
    margin: auto 2rem;
  } */
}
@media screen and (max-width: 600px) {
  .drupasection{
    padding-top: 0.5rem;
    background-color: #f7f7f7;
  }
  .validations {
    width: 100%;
    margin: 0 auto;
  }
  .drupa{
    position: relative;
    width: 100%;
    height: 45vh;
    background:url('https://www.nesscoindia.com/Assets/images/resource/drupabanner3.webp') center center ;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding-top: 1rem; */
  }
  .drupa2{
    position: relative;
    width: 100%;
    height: 45vh;
    background:url('https://www.nesscoindia.com/Assets/images/resource/drupabanner4.webp') center center ;
    background-size: cover;
    background-repeat: no-repeat;
    /* padding-top: 1rem; */
  }
  /* .drupalayers{
    width: 50px;
    left: 1%;
  }
  .drupaleftlogo{
    margin-right: 0.5rem;
  } */
  .drupadates{
    top: 55%;
    left: 3%;
  }
  .drupalocation{
    top: 65%;
    left: 3%;
  }
  .drupalayers{
    width: 85px;
    top: 34%;
    left: 3%;
  }
  .drupadatesright>p,.drupalocationright >p{
    color: white;
    font-weight: 600;
    font-size: 1.0rem !important;
  }
  .drupacontent{
    font-size: 1.0rem;
  }
  .drupabooth{
    top: 75%;
    left: 3%;
  }
  .drupatable{
    width: 85px;
  }
  /* .drupatable{
    width: 90px;
  }
  .drupadates{
    top: 37%;
    left: 15%;
  }
  .drupalocation{
    top: 46%;
    left: 15%;
  }
  .drupabooth{
    top: 55%;
    left: 15%;
  }
  .drupadatesright>p,.drupalocationright >p{
    color: white;
    font-weight: 600;
    font-size: 1rem !important;
  }
  .drupacontent{
    font-size: 1rem;
  }
  .bannerslide {
    width: 90%;
    margin: auto;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  } */
  .iotsection{
    min-height: 150vh;
  }
  .bannerslide::after{
    animation: none;
  }
  .home-page {
    display: inline;
    align-items: start;
    justify-content: start;
    text-align: start;
    width: 100%;
  }
  .imglayer1,
  .imglayer2,
  .imglayer3,
  .imglayer4,
  .imglayer5 {
    display: none;
  }
  .bannerslidepara {
    text-align: start;
    font-size: 1.6rem;
  }
  .bannerslideheading {
    font-size: 3.5rem;
  }
  .rightabout {
    width: 100%;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  .wenesscobuttons {
    display: none;
  }
  .wenessco {
    height: 100%;
  }
  .wenesscocontainer {
    flex-direction: column;
    overflow-x: scroll;
  }
  .wenesscoleft {
    width: 100%;
    display: none;
  }
  .wenesscoheading {
    /* di */
    width: 100%;
    font-size: 3rem;
  }
  .wenesscoright {
    width: 100%;
  }
  .wenesscocard {
    min-width: 80%;
    height: 100%;
    /* overflow-x: scroll; */
  }
  .wenesscocardleft {
    width: 100%;
    height: 100px;
  }
  .wenesscocardleft > img {
    width: 100%;
  }
  .wenesscocardright {
    display: none;
  }
  .sectionContainer {
    width: 90%;
    flex-direction: column;
  }
  .leftabout {
    width: 100%;
    margin-top: 4rem;
  }

  .rightabout {
    width: 100%;
    margin: auto;
    margin-top: 20rem;
    margin-bottom: 4rem;
  }
  .bannerslideheading{
    font-size: 3.1rem;
    opacity: 1;
    animation: none;
    color: white;
  }
  .bannerslideheading span{
    opacity: 1;
    animation: none;
    color: white;
  }
  .bannerslidepara{
    opacity: 1;
    animation: none;
  }
  .leftaboutpic2 {
    width: 30%;
    height: 200px;
  }

  .getaquotebtn {
    height: 5rem;
  }
  .signatureimg {
    width: 15%;
  }
  .testimonialscontainer {
    width: 90%;
  }
  .creation {
    width: 100%;
    height: 100vh;
    background-color: khaki;
    background: url("https://www.nesscoindia.com/Assets/images/background/video-bg.webp")
      no-repeat bottom center/150%;
    /* position: relative; */
  }

  .creation::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    background: #000000;
    opacity: 0.6;
    z-index: 1;
  }
  .creationpattern1,
  .creationpattern2,
  .creationpattern3,
  .creationpattern4 {
    display: none;
  }
  .creationcontainer {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
  }
  .creationcontent {
    width: 100%;
  }
  .creationheading {
    font-size: 3.5rem;
  }
  .youtubebtn {
    margin-bottom: 4rem;
  }
  .iotcontainer {
    flex-direction: column;
  }
  .leftiot {
    width: 100%;
  }
  .rightiot {
    width: 100%;
    margin-top: 4rem;
  }
  .presenceContainer {
    flex-direction: column;
  }
  .leftpresence {
    width: 100%;
    margin-top: 0;
  }
  .rightpresence {
    width: 100%;
  }
  .presenceCard {
    width: 100%;
  }
  .mm {
    margin-top: 10rem;
  }
  .testimonials {
    height: 90vh;
  }
  .testimonialscontainer {
    flex-direction: column;
    justify-content: flex-start;
  }
  .testimonialsleft {
    width: 100%;
  }
  .testimonialsright {
    width: 100%;
  }
  .testimonialscards {
    width: 100%;
  }
  .testimonialsec {
    width: 80%;
  }
  .testimonialcard {
    width: 100%;
  }
  .testimonialowner {
    /* margin-right: 15rem; */
  }
  .homestats {
    height: 45vh;
  }
  .homestatcontentbox {
    min-width: 45%;
  }
  .article {
    z-index: -1;
  }
  .articlecards {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .articlecards {
    margin-bottom: 3rem;
  }
  .articlecard {
    width: 100%;
    margin: auto;
    margin: 1rem auto;
    /* z-index: -1; */
  }
  .lowersection {
    padding: 2rem;
  }
  .articleheading {
    font-size: 1.9rem;
  }
  .condition2 {
    margin: 0;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
  .subscribe {
    height: 180px;
  }
  .subsribecontainer {
    flex-direction: column;
  }
  .rightsubscribe > input {
    width: 100%;
  }
  .leftsubscribe {
    width: 100%;
  }
  .rightsubscribe {
    width: 100%;
    margin-bottom: 2rem;
  }
  .subscribebtn {
    margin-left: 0;
  }
  .aboutusbtn {
    margin-top: 2rem;
  }
  .productsssdesc {
    width: 100%;
  }
  /* .productbox {
    overflow-x: hidden;
    margin: 2rem auto;
  }
  .product {
    min-width: 28%;
    margin: auto 2rem;
  } */
  .subscribe {
    height: 300px;
  }
  .leftsubscribe {
    width: 100%;
  }
  .rightsubscribe {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .mm {
    margin-top: 12rem;
    /* margin-right: 2rem ; */
  }
  .testimonialsec {
    width: 80%;
    z-index: -1;
  }
  .testimonialowner {
    width: 100%;
    margin-right: 0;
  }
  .iotlist {
    flex-direction: column;
  }
  .iotlist > li {
    width: 80%;
  }
  .iotpara {
    word-spacing: 2px;
    font-size: 1.5rem;
  }
  .creation {
    background-size: cover;
  }
  .creationheading {
    font-size: 3rem;
  }
  .leftaboutpic {
    display: none;
  }
  .leftaboutpic2 {
    width: 100%;
    left: 0%;
    top: 0;
    z-index: 1;
  }
  .KK {
    margin-top: 10rem;
  }
  .rightsubscribe {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 1rem ; */
  }
  .subscribebtn {
    width: 100%;
    margin-top: 1rem;
  }
  .signatureimg {
    width: 30%;
  }
  .aboutpara {
    font-weight: 500;
  }
  .iotheading {
    font-size: 3rem;
  }
  .iotpara {
    font-weight: 500;
  }
  .presencepara {
    font-weight: 500;
  }
  .staticimage1,
  .staticimage2 {
    display: none;
  }
  .logos-slide img {
    height: 30px;
    margin: 0 5px;
  }
}
@keyframes hamburger_puls {
  0% {
    opacity: 1;
    /* transform: scale(1); */
    border: 1px solid white;
  }
  100% {
    opacity: 0;
    /* transform: scale(1.4); */
    border: 30px solid white;
  }
}
@keyframes hamburger_puls2 {
  0% {
    opacity: 1;
    /* transform: scale(1); */
    border: 1px solid white;
  }
  100% {
    opacity: 0;
    /* transform: scale(1.4); */
    border: 30px solid white;
  }
}
@keyframes walkingshadow {
  0%,
  100% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }

  25% {
    transform: translate3d(-15px, 5px, 0) rotateZ(0.01deg);
  }
  35% {
    transform: translate3d(-30px, 5px, 0) rotateZ(0.01deg);
  }
  45% {
    transform: translate3d(-15px, 5px, 0) rotateZ(0.01deg);
  }
  55% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  75% {
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
