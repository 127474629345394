.marketarea {
  width: 100%;
  min-height: 70vh;
}
.marketareacontainer {
  width: 85%;
  height: 100%;
  margin: auto;
  display: flex;
}
.marketarealeft {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}
.marketarearight {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  margin: 0 auto;
}
.marketareaheading {
  font-size: 3rem;
  font-weight: 600;
  color: var(--bluecolor);
  margin: 2rem 0;
}
.marketareacard {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  /* margin-top: 5rem; */
}
.marketareainp {
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(128, 128, 128, 0.459);
  resize: vertical;
}
.marketplacebtn {
  padding: 1rem;
  background-color: var(--bluecolor);
  border: none;
  color: white;
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 5px;
}
.marketcardcontainer {
  padding: 3rem;
  width: 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 10px;
}
.marketlink {
  font-size: 1.5rem;
  margin: 1rem 0;
  width: 30%;
  color: var(--bluecolor);
}
.marketlink:hover {
  color: var(--redcolor);
}
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .marketarealeft {
    width: 40%;
    margin-top: 5rem;
  }
  .marketarearight {
    width: 50%;
    margin-top: 5rem;
  }
  .marketlink {
    width: 46%;
  }
  .marketareacontainer {
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .marketarea {
    height: auto;
  }
  .marketareacontainer{
    flex-direction: column;
  }
  .marketarealeft{
    width: 100%;
    height: auto;
  }
  .marketarearight{
    width: 100%;
  }
  .marketcardcontainer{
    flex-direction: column;
  }
  .marketlink{
    width: 100%;
  }
  .marketarearight{
    margin-bottom: 2rem;
  }
}
.rtl {
  direction: rtl;
  text-align: right;
}

