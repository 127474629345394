.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  .modal-content {
    position: relative;
    padding: 20px;
    width: 80%;
    height: 100%;
    background-color: white;
  }
  
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    font-size: 50px;
    width: 50px;
    height: 50px;
    color: red;
    cursor: pointer;
    /* background-color: black; */
  }
  .close-button:hover{
    color: var(--redcolor);
  }