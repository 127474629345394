.footer {
  /* background-color: crimson ;/ */
  /* width: 100%; */
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.bgimagefooter {
  position: absolute;
  background: url("https://www.nesscoindia.com/Assets/images/shape/wmap.webp");
  width: 100%;
  height: 100%;
  background-repeat: repeat-x;
  background-size: cover;
  animation: movingBackground 80s linear infinite;
}
.footercontainer {
  width: 84.5%;
  margin: auto;
  position: relative;
}
.footerlinks {
  display: flex;
  justify-content: end;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.footerlink {
  color: var(--bluecolor);
  font-size: 1.6rem;
  font-weight: 600;
  margin: 1rem 1.5rem;
}
.footerlink:hover {
  color: var(--redcolor);
}
.horizontaldiv {
  margin: 1rem 0;
  opacity: 0.3;
}
.footercontent {
  margin-bottom: 2rem;
}
.footercontent {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin-top: 7rem;
}
.footerlistabout {
  width: 33%;
}

.footerlist {
  width: 16%;
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
}
.footerdesccontent {
  font-size: 1.4rem;
  color: black;
}
.footerlistslink {
  display: flex;
  flex-direction: column;
}
.footersocialicons {
  display: flex;
  font-size: 1.5rem;
  color: black;
}
.footersocialicon {
  width: 40px;
  height: 40px;
  font-size: 16px;
  padding: 1.2rem;
  margin: 0.6rem;
  background-color: #f0f0f0; /* Fallback color */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black; /* Default text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 1;

  /* Transition for text color change */
  transition: color 1s ease;
}
@keyframes movingBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 1920px 0;
  }
}

.footersocialicon::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: #e11e25; /* Color for the fill effect */
  transition: all 0.5s ease;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 5px; /* Adjust if you want rounded corners */
}

.footersocialicon:hover::before {
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.footersocialicon:hover {
  color: white; /* Text color on hover */
}

.footerdesccontent {
  font-size: 1.4rem;
  line-height: 2.5rem;
  color: black;
  margin: 2rem 0;
}
.footeraddress {
  font-size: 1.4rem;
  color: black;
}
.footeratags {
  font-size: 1.4rem;
  color: black;
  margin: 0.8rem 0;
}
.footeratags:hover {
  color: var(--redcolor);
}
.footerdesctitle{
  font-size: 1.5rem;
}
.footerlistheading {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}
.footerdesccontent .footeraddress,
.mobilenumber,
.email {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  color: black;
}
.footerrights {
  width: 100%;
  height: 80px;
  padding: 1rem 0;
  background-color: #f0f0f0;
}
.footercontainerx {
  width: 80%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
}
.footerright {
  font-size: 1.5rem;
  color: black;
  /* width: 90%; */
  /*margin: auto 0; */
}
.footerright:hover {
  color: var(--redcolor);
}
.footerlist:nth-child(4) {
  width: 26%;
}
@media screen and (min-width: 771px) and (max-width: 10px) {
  .footercontent {
    flex-wrap: wrap;
  }
  .footerlistabout,
  .footerlist {
    width: 45%;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
  .footerlist:nth-child(4) {
    width: 47%;
  }
}
@media (max-width: 770px) {
  .footercontainer{
    width: 90%;
  }
  .bgimagefooter{
    width: 170%;
    height: 76%;
  }
  .footercontent {
    flex-direction: column;
  }
  .footerlistabout {
    width: 100%;
  }
  .footerlist {
    width: 100%;
  }
  .footerlistheading {
    margin-top: 2rem;
  }
  .footeraddress {
    text-align: justify;
  }
  .footerrights {
    padding: 2rem 1rem;
    margin-bottom: 8rem;
  }
  .footercontainerx {
    flex-direction: column;
  }
  .footerlinks {
    justify-content: center;
    align-items: center;
  }
  .footerlist:nth-child(4) {
    width: 100%;
  }
  footer {
    z-index: -1;
  }
}
